import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Наследственные споры | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ В наследственных спорах при наследовании по завещанию особенно требуется участие квалифицированного юриста. " />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/nasledstvennye-spory'
				name="Наследственные споры | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ В наследственных спорах при наследовании по завещанию особенно требуется участие квалифицированного юриста. "
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Наследственные споры" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Наследственные споры"}
			
				html={

				`

				<p class="TextText">
				Споры о наследстве широко распространены в юридической практике. Порой  они становятся причиной разлада  в некогда дружной семье.
				</p>

				<p class="TextText">
				Действующее законодательства предусматривает  два вида наследования: по закону и  по завещанию.
				</p>

				<p class="TextText">
				При принятии наследства по закону очень часто возникают споры между наследниками различных очередей, главным образом между наследниками первой и второй очереди. Спорными вопросами становятся доли в наследстве, права на недвижимость, вклады и т. д.	
				</p>

				<p class="TextText">
				При вступлении в наследство необходимо учитывать срок для принятия наследства. Согласно ст. 1154 ГК РФ наследство может быть принято в течение шести месяцев со дня открытия наследства. Наследник, который пропустил полугодичный срок для вступления в наследство, может восстановить его. Для этого у него должны быть уважительные причины пропуска срока: болезнь, длительная командировка, неведение относительно смерти наследодателя. Восстановление пропущенного срока для принятия наследства осуществляется исключительно в судебном порядке.
				</p>

				<p class="TextText">
				Также в случае пропуска срока принятия наследства возможно обращение в  суд с исковым заявлением об установлении факта принятия наследства. Данный вид иска возможен при фактическом  вступлении наследника по закону в  наследство, когда наследник по тем или иным причинам в течение шести месяцев со дня смерти наследодателя не обратился с письменным заявлением к нотариусу. Согласно ст. 1153 ГК РФ наследство считается принятым, если наследник совершил действия, свидетельствующие о фактическом принятии наследства, например, забрал или просто продолжил пользоваться имуществом, входящим в наследственную массу умершего. Однако сложностью данного процесса является участие в нем заинтересованных лиц, а именно государства в лице налоговых органов. Поэтому не следует дожидаться крайнего срока, а в первый же месяц после открытия наследства обратиться к нотариусу. Иначе можно остаться без наследства или быть втянутым в длительную судебную тяжбу с наследниками, сделавшими все вовремя.
				</p>

				<p class="TextText">
				В наследственных спорах при наследовании по завещанию особенно требуется участие квалифицированного юриста. Нужно иметь ввиду, что законодатель устанавливает четкие требования, касающиеся формы и порядка совершения завещания. Согласно ст. 1125 ГК РФ завещание должно быть собственноручно подписано завещателем. Если завещатель в силу физических недостатков, тяжелой болезни или неграмотности не может собственноручно подписать завещание, оно по его просьбе может быть подписано другим гражданином в присутствии нотариуса. В завещании должны быть указаны причины, по которым завещатель не мог подписать заве щание собственноручно, а также фамилия, имя, отчество и место жительства гражданина, подписавшего завещание по просьбе завещателя, в соответствии с документом, удостоверяющим личность этого гражданина. Если при составлении, подписании, удостоверении завещания или при передаче завещания нотариусу присутствуют свидетели, то нужно учитывать, что в соответствии с ч. 2. ст. 1124 ГК РФ не могут быть такими свидетелями и не могут подписывать завещание вместо завещателя:
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					нотариус или другое удостоверяющее завещание лицо
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					лицо, в пользу которого составлено завещание или сделан завещательный отказ, супруг такого лица, его дети и родители
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					граждане, не обладающие дееспособностью в полном объеме
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					неграмотные
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					граждане с такими физическими недостатками, которые явно не позволяют им в полной мере осознавать существо происходящего
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					лица, не владеющие в достаточной степени языком, на котором составлено завещание, за исключением случая, когда составляется закрытое завещание
				</p>

				<p class="TextText">
				Таким образом, сам нотариус (другое удостоверяющее завещание лицо) в качестве свидетелей выступать не могут. Запрещается  также при совершении завещания  присутствие заинтересованного  лица, в пользу которого составлено завещание или сделан завещательный отказ, а также его супруга (супруги), детей и родителей из-за возможного влияния на формирование воли завещателя, что нарушило бы принцип свободы Ситуации по наследственным спорам бывают разные. Зачастую на наследство претендуют люди, хоть и имеющие право на имущество умершего, однако недостойные его. В этом случае речь идет о признании гражданина недостойным наследником. В большинстве случаев для того, чтобы признать гражданина таковым, требуется соответствующее решение суда.
				</p>

				<p class="TextText">
				Конечно, понятие наследственных споров не исчерпывается вышеприведенными примерами, в жизни возникают самые различные ситуации, связанные с наследственными спорами. Любые наследственные споры рассматриваются в судебном порядке судами общей юрисдикции. Суд в случае спора выносит решение о разделе наследства или признании права на него за одним из наследников. Конечно, большая часть споров о разделе наследуемого имущества приходится на споры о разделе недвижимости, т.к. квартирный вопрос на сегодняшний день стоит особенно остро.


				</p>

				<p class="TextText">
				Самыми распространенными спорами о наследстве являются споры между наследниками: о размере наследственных долей, оспаривание завещания, определение недостойных наследников и лиц, отстраненных от наследования, признание завещания недействительным, установление обязательной доли и др.
				</p>

				<p class="TextText">
				Наследственные споры относятся к категории сложных, поэтому в случае их возникновения стоит обратиться за квалифицированной помощью к специалисту. Мы поможем Вам в разрешении возникшего наследственного спора как в досудебном порядке путем переговоров и поиска компромисса, так и путем защиты Ваших интересов в суде.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
